import { ErrorPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  trialMatchCard: {
    margin: '16px 0',
    height: 'auto',
    padding: '16px 0px',
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.1)',

    '& > div > div': {
      overflowY: 'hidden',
      padding: 0,
    },
  },
  trialDetailsSection: {
    padding: '0 16px',
  },
  matchStatusSection: {
    padding: '0 16px',
  },
  matchStatusTitle: {
    marginBottom: '5px',
  },
  matchStatusDropdownContainer: {
    zIndex: 7,
  },
  header: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  twoColumns: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  matchDetailsCard: {
    backgroundColor: GrayscalePalette[200],
    padding: '16px',
    boxShadow: 'none',

    '& > div > div': {
      overflowY: 'hidden',
    },
  },
  biomarkers: {
    display: 'flex',
    alignItems: 'start',
    gap: '16px',
    marginBottom: '16px',

    '& > div:first-child': {
      textWrap: 'nowrap',
    },
  },
  biomarkersTextArea: {
    marginBottom: '16px',

    '& > textarea': {
      width: '100%',
      height: '86px',
    },
  },
  inactiveMatchesCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '14px',
  },
  inactiveCardTitle: {
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
  },
  inactiveCardTitleText: {
    paddingLeft: '12px',
    fontWeight: 600,
  },
  inactiveCardLink: {
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  inactiveCardMessage: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
  },
  trialMaterials: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontWeight: 600,
    color: 'black',
    cursor: 'pointer',
    marginBottom: '16px',
  },
  divider: {
    margin: '16px 0',
    height: '1px',
    backgroundColor: GrayscalePalette[300],
  },
  oneLineStatus: {
    width: '378px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '16px',
  },
  statusDropdown: {
    marginBottom: '4px',
  },
  reasonDropdown: {
    marginTop: '12px',
    marginBottom: '16px',
    // override the default height of reason not a match dropdown to show all options in viewport (fix for smaller screens)
    '& li:first-of-type': {
      maxHeight: '200px !important',
    },
  },
  warning: {
    marginTop: '-10px',
    marginBottom: '10px',
    fontSize: '12px',
    color: `${ErrorPalette[700]}`,
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    height: '12px',
    marginRight: '4px',
    marginBottom: '2px',
  },
  additionalDetails: {
    marginBottom: '4px',
  },
});
