import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { TrialType, LfsTrialStatus, TimePatientStatuses, TimePatientStatusesDetail } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUS_ROLLUP } from '@tempus/t-shared/src/constants/patient-tracker';
import React from 'react';
import { DropdownOption } from 'tcl-v3/models';

export enum PATIENT_TRACKER_STATUSES {
  IMMINENT_MATCH = 'Imminent match',
  PURSUING_ACTIVATION = 'Pursuing activation',
  PENDING_SITE_REVIEW = 'Pending site review',
  PENDING_TEMPUS_REVIEW = 'Pending Tempus review',
  CONSENTED = 'Consented',
  WATCHLIST = 'Watchlist',
  ENROLLED = 'Enrolled',
  NO_LONGER_A_CANDIDATE = 'No longer a candidate',
  URGENT = 'Urgent',
  TRIAL_COHORT_ON_HOLD = 'Trial/Cohort on hold',
  SITE_ON_HOLD = 'Site on hold',
}

export enum PATIENT_TRACKER_STATUS_CATEGORIES {
  PRIORITY = 'Priority',
  NEW = 'New',
  MONITORING = 'Monitoring',
  ENROLLED = 'Enrolled',
  INACTIVE = 'Inactive',
}

export const PATIENT_TRACKER_CATEGORY_LOOKUP = Object.entries(PATIENT_TRACKER_STATUS_ROLLUP).reduce(
  (acc, [category, statusArray]) => {
    statusArray.forEach((status) => {
      acc[status] = category;
    });

    return acc;
  },
  {},
);

export enum VisitType {
  PROVIDER_VISIT = 'Provider Visit',
  BLOOD_DRAW = 'Blood Draw',
  SCAN = 'Scan',
  OTHER = 'Other',
}

export enum PATIENT_TRACKER_ACKNOWLEDGED_STATUS {
  NEEDS_REVIEW = 'Needs Review',
  NOT_APPROVED = 'Not Approved',
  APPROVED = 'Approved',
}

export const renderExternalPatientStatus = (status: string) => {
  switch (status) {
    // Display as "New - Pending Site/Tempus Review" as requested by Design Team
    // Avoided backend change in QB because much logic in QB & LinkSites tied to these statuses
    // and QB is being deprecated in two quarters
    case PATIENT_TRACKER_STATUSES.PENDING_SITE_REVIEW:
      return `New - ${status}`;
    default:
      return status;
  }
};

export enum SORT_OPTION {
  URGENCY = 'Urgency',
  UPCOMING_VISIT = 'Upcoming visit',
  OLDEST = 'Date added (oldest first)',
  NEWEST = 'Date added (newest first)',
  MATCH_STATUS = 'Match status',
}

export const getSortDropdownOptions = (): DropdownOption[] =>
  Object.keys(SORT_OPTION).map((key) => ({
    label: SORT_OPTION[key],
    value: key,
  }));

const trialStatusDisplayNames = {
  [LfsTrialStatus.ACTIVE]: 'Active at my site',
  [LfsTrialStatus.AVAILABLE_TO_ACTIVATE]: 'Available to activate',
  [LfsTrialStatus.ON_HOLD]: 'On hold',
};

export const getTrialStatusDropdownOptions = (): DropdownOption[] => {
  const options = [LfsTrialStatus.ACTIVE, LfsTrialStatus.AVAILABLE_TO_ACTIVATE, LfsTrialStatus.ON_HOLD];
  return options.map((opt) => ({
    label: trialStatusDisplayNames[opt],
    value: opt,
  }));
};

export const getPatientTrackerStatusDropdownOptions = (): DropdownOption[] =>
  Object.keys(PATIENT_TRACKER_STATUSES).map((key) => ({
    label: PATIENT_TRACKER_STATUSES[key],
    value: PATIENT_TRACKER_STATUSES[key],
  }));

export const getVisitTypeDropdownOptions = (): DropdownOption[] =>
  Object.keys(VisitType).map((key) => ({
    label: VisitType[key],
    value: VisitType[key],
  }));

export const getTrialTypeDropdownOptions = (): DropdownOption[] =>
  Object.keys(TrialType).map((key) => ({
    label: TrialType[key],
    value: TrialType[key],
  }));

export const getPatientStatusDropdownOptions = (): DropdownOption[] =>
  Object.keys(TimePatientStatuses).map((key) => ({
    label: TimePatientStatuses[key],
    value: TimePatientStatuses[key],
    subContent: React.createElement('div', { style: { color: GrayscalePalette[600] } }, TimePatientStatusesDetail[key]),
  }));

export enum PATIENT_OVERLAY_TAB_ID {
  ACTIVITIES = 'activities',
  PATIENT_DETAILS = 'patientDetails',
  INACTIVE_MATCHES = 'inactiveMatches',
}
