import { PROGRAM_TYPES_DEC } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUS_ROLLUP } from '@tempus/t-shared/src/constants/patient-tracker';
import { DropdownOption } from 'tcl-v3/models';

import { SearchArm } from '~/store/api/types';
import { PATIENT_TRACKER_STATUSES, renderExternalPatientStatus } from '~/store/patientTrackerV2/constants';

export const shouldExcludePendingTempusReview = (cohorts: SearchArm[]) => {
  if (!cohorts.length) {
    return false;
  }
  return cohorts.every((c) => c.programType === PROGRAM_TYPES_DEC.ST);
};

export const getPatientStatusOptions = (shouldExcludePendingTempusReview: boolean) =>
  Object.entries(PATIENT_TRACKER_STATUS_ROLLUP).reduce((acc: DropdownOption[], [, categoryStatuses]) => {
    // Pending Site Review is not a valid option for the AddPatient or UpdatePatient modals by external site users
    // and will cause errors due to missing required fields (e.g. passedPharmaOpsReview)
    const validStatuses = categoryStatuses.filter((status) => status !== PATIENT_TRACKER_STATUSES.PENDING_SITE_REVIEW);
    const filteredStatuses = shouldExcludePendingTempusReview
      ? validStatuses.filter((status) => status !== PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW)
      : validStatuses;
    const options = filteredStatuses.map((status) => ({ label: renderExternalPatientStatus(status), value: status }));
    return acc.concat(options);
  }, []);
