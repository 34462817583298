import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import { get } from 'lodash';
import React from 'react';

import { PatientTrackingDetails } from '~/store/patientTrackerV2/types';
import { formatDateForDisplay } from '~/utils/misc';

import useStyles from './InactiveMatchesTab.styles';

const sortMatchesByStatusDate = (a: PatientTrackingDetails, b: PatientTrackingDetails) => {
  if (!a.statusDate && !b.statusDate) {
    return 0;
  }

  if (!a.statusDate) {
    return -1;
  }

  if (!b.statusDate) {
    return 1;
  }

  const dateA = new Date(a.statusDate);
  const dateB = new Date(b.statusDate);
  return dateA.getTime() - dateB.getTime();
};

interface InactiveMatchesTabProps {
  inactiveMatches: PatientTrackingDetails[];
}

export default ({ inactiveMatches }: InactiveMatchesTabProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {inactiveMatches.sort(sortMatchesByStatusDate).map((match) => (
        <div key={match.trial.id} className={classes.match}>
          <div className={classes.trialTitle}>{get(match, 'trial.shortName')}</div>
          <div>{get(match, 'arms[0].name')}</div>
          <div className={classes.statusRow}>
            <div>
              <i>{match.status}</i>
              {match.status === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE && ` - ${match.reasonNotAMatch}`}
            </div>
            <div>{Boolean(match.statusDate) && `${formatDateForDisplay(match.statusDate, true)}`}</div>
          </div>
          {match.status === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE && <div>{match.reasonNotAMatchDetails}</div>}
        </div>
      ))}
    </div>
  );
};
