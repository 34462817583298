import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    height: '100%',
    padding: '24px',
    backgroundColor: GrayscalePalette[200],
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    overflowY: 'auto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  match: {
    paddingBottom: '16px',

    '&:not(:first-child)': {
      paddingTop: '12px',
    },

    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(233, 233, 234, 1)',
    },
  },
  trialTitle: {
    fontWeight: 600,
  },
  statusRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
