import { NavTab, NavTabGroup } from '@tcl-boron-prefabs/nav-tab';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ROLES } from '@tempus/t-shared';
import {
  PATIENT_TRACKER_STATUS_CATEGORIES,
  PATIENT_TRACKER_STATUS_ROLLUP,
} from '@tempus/t-shared/src/constants/patient-tracker';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { PATIENT_OVERLAY_TAB_ID } from '~/store/patientTrackerV2/constants';
import { TimePatient } from '~/store/patientTrackerV2/types';

import useStyles from './RightPane.styles';
import { ActivityTab } from './TabContents/ActivitiesTab/ActivityTab';
import InactiveMatchesTab from './TabContents/InactiveMatchesTab';
import PatientDetailsTab from './TabContents/PatientDetailsTab';

interface RightPaneProps {
  stateId: string;
  timePatient: TimePatient;
  setShouldFetchData: React.Dispatch<React.SetStateAction<boolean>>;
  setIsThereUnsavedNotes: React.Dispatch<React.SetStateAction<boolean>>;
  refreshData: () => void;
  currentTab: PATIENT_OVERLAY_TAB_ID;
  setCurrentTab: (tabID: PATIENT_OVERLAY_TAB_ID) => void;
}
export default ({
  stateId,
  timePatient,
  setShouldFetchData,
  setIsThereUnsavedNotes,
  refreshData,
  currentTab,
  setCurrentTab,
}: RightPaneProps) => {
  const classes = useStyles();
  const { effectiveRoles } = useSelector((state: RootState) => state.user);

  const tabs = [
    {
      id: PATIENT_OVERLAY_TAB_ID.ACTIVITIES,
      display: 'Activity',
    },
    {
      id: PATIENT_OVERLAY_TAB_ID.PATIENT_DETAILS,
      display: 'Patient details',
      allowedRoles: [ROLES.T_PATIENT_TRACKER_READ_INTERNAL],
    },
  ];

  const inactiveMatches = timePatient.patientTrackingDetails.filter((ptd) =>
    PATIENT_TRACKER_STATUS_ROLLUP[PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE].includes(ptd.status),
  );

  if (inactiveMatches.length) {
    tabs.push({
      id: PATIENT_OVERLAY_TAB_ID.INACTIVE_MATCHES,
      display: 'Inactive matches',
    });
  }

  const renderTabs = () =>
    tabs.map(({ id, display, allowedRoles }) => {
      if (allowedRoles && !effectiveRoles.some((role) => allowedRoles.includes(role))) {
        return;
      }

      return (
        <NavTab
          value={id}
          key={id}
          onClick={() => setCurrentTab(id)}
          className={classes.navTab}
          activeClassName={classes.activeTab}
          data-testid={`update-overlay-${id}-tab`}>
          <strong className={typography.body}>{display}</strong>
        </NavTab>
      );
    });

  const renderContent = () => {
    switch (currentTab) {
      case PATIENT_OVERLAY_TAB_ID.ACTIVITIES:
        return (
          <ActivityTab
            stateId={stateId}
            patientLinkageId={timePatient.patientLinkageId}
            responseRequired={timePatient.responseRequired}
            institutionId={timePatient.institution.id}
            activities={timePatient.activities}
            setShouldFetchData={setShouldFetchData}
            setIsThereUnsavedNotes={setIsThereUnsavedNotes}
            onActivityUpdate={refreshData}
          />
        );
      case PATIENT_OVERLAY_TAB_ID.PATIENT_DETAILS:
        return <PatientDetailsTab timePatient={timePatient} />;
      case PATIENT_OVERLAY_TAB_ID.INACTIVE_MATCHES:
        return <InactiveMatchesTab inactiveMatches={inactiveMatches} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <NavTabGroup value={currentTab} className={classes.navTabGroup}>
          {renderTabs()}
        </NavTabGroup>
        {renderContent()}
      </div>
    </div>
  );
};
