import React from 'react';

import { TimePatient } from '~/store/patientTrackerV2/types';

import useStyles from './PatientDetailsTab.styles';

interface PatientDetailsTabProps {
  timePatient: TimePatient;
}
export default ({ timePatient }: PatientDetailsTabProps) => {
  const classes = useStyles();
  const patientTempusId = timePatient.patient.id;
  const ptdTimePatientIds = timePatient.patientTrackingDetails.map(({ timePatientId }) => timePatientId);

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        {patientTempusId && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>Tempus ID</div>
              <div>{patientTempusId}</div>
            </div>
          </div>
        )}
        {!!ptdTimePatientIds.length && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>TIME ID</div>
              {ptdTimePatientIds.map((timePatientId) => (
                <div key={timePatientId}>{timePatientId}</div>
              ))}
            </div>
          </div>
        )}
        {!ptdTimePatientIds.length && timePatient.billingId && (
          <div className={classes.detailGroup}>
            <div>
              <div className={classes.label}>Billing ID</div>
              <div>{timePatient.billingId}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
