import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, MatchStatusUpdates } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const MatchStatusUpdateActivity = ({ activity }: { activity: Activity }) => {
  const trials = useSelector(({ trial }: RootState) => trial.allTrials);
  const classes = useStyles();

  return (
    <>
      <li key={activity.id} className={cn(typography.body, classes.li)}>
        <div className={classes.listItemContainer}>
          <div className={classes.updatesLeft}>
            <div>
              Patient Updated to <em>{(activity.content as MatchStatusUpdates).newStatus}</em> for{' '}
              <strong>{getTrialDisplay((activity.content as MatchStatusUpdates).trialId, trials)}</strong>
            </div>
            {(activity.content as MatchStatusUpdates).newStatus === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE && (
              <>
                <div>Reason: {(activity.content as MatchStatusUpdates).reasonNotAMatch}</div>
                {(activity.content as MatchStatusUpdates).reasonNotAMatchDetails && (
                  <div>Additional details: {(activity.content as MatchStatusUpdates).reasonNotAMatchDetails}</div>
                )}
              </>
            )}
          </div>
          <div>{activity.createdAt}</div>
        </div>
      </li>
    </>
  );
};
